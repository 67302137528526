import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n
import 'normalize.css/normalize.css'
import util from './views/utils/util.js'

// Vue.prototype.$axios = axios;
Vue.prototype.$util = util;

import request from './api/require.js' // Vue原始原型链请求
import '@/styles/index.scss' // A modern alternative to CSS resets

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.transformRequest = [function (data) {
  let ret = ''
  for (const it in data) {
    ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
  }
  if (ret.length > 0) {
    ret = ret.slice(0, ret.length - 1)
  }
  // console.log(ret.charAt(ret.length-1));
  return ret
}]
Vue.prototype.$request = request
global.Axios = axios // 普通请求

Vue.prototype.$http = axios // global css

// Vue.use(ElementUI);
// global.Axios = Axios
Vue.use(ElementUI, { locale })

Vue.config.productionTip = false

// router.beforeEach((to, from, next) => {
//   if (to.meta.title) {
//     document.title = to.meta.title
//   }
//   next()
// })
// 全局路由守卫
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.matched.some(res => res.meta.role)) { // 判断是否需要登录权限
    if (localStorage.getItem('Token')) { // 判断是否登录
      next()
    } else { // 没登录则跳转到登录界面
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    }
  } else {
    next()
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
