import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
	routes: [{
			path: '/login',
			name: 'login',
			component: () => import('@/views/login/index'),
			meta: {
				title: '登录'
			}
		},
		{
			path: '/',
			name: 'layout',
			component: () => import('@/views/layout.vue'),
			meta: {
				role: true
			},
			children: [{
					path: 'agentList',
					name: 'agentList',
					component: () => import('@/views/equipment/agentList.vue'),
					meta: {
						title: '代理列表'
					}
				},
				{
					path: 'equipment',
					name: 'equipment',
					component: () => import('@/views/equipment/equipment.vue'),
					meta: {
						title: '添加代理'
					}
				},
				{
					path: 'Didaudit',
					name: 'Didaudit',
					component: () => import('@/views/equipment/Didaudit.vue'),
					meta: {
						title: '待审核'
					}
				},
				{
					path: 'Alreadyagree',
					name: 'Alreadyagree',
					component: () => import('@/views/equipment/Alreadyagree.vue'),
					meta: {
						title: '已同意'
					}
				},
				{
					path: 'Alreadyrefuse',
					name: 'Alreadyrefuse',
					component: () => import('@/views/equipment/Alreadyrefuse.vue'),
					meta: {
						title: '已拒绝'
					}
				},
				{
					path: 'advUserList',
					name: 'advUserList',
					component: () => import('@/views/advUser/advUserList.vue'),
					meta: {
						title: '广告主列表'
					}
				},
				{
					path: 'advListApply',
					name: 'advListApply',
					component: () => import('@/views/advUser/advUserApply.vue'),
					meta: {
						title: '广告主申请'
					}
				},
				{
					path: 'advApprovalRecord',
					name: 'advApprovalRecord',
					component: () => import('@/views/advUser/advApprovalRecord.vue'),
					meta: {
						title: '审核记录'
					}
				},
				{
					path: 'advAgencyList',
					name: 'advAgencyList',
					component: () => import('@/views/advUser/advAgencyList.vue'),
					meta: {
						title: '审核记录'
					}
				},
				{
					path: 'advAgencyApply',
					name: 'advAgencyApply',
					component: () => import('@/views/advUser/advAgencyApply.vue'),
					meta: {
						title: '广告商认证申请'
					}
				},
				{
					path: 'advAgencyApprovalRecord',
					name: 'advAgencyApprovalRecord',
					component: () => import('@/views/advUser/advAgencyApprovalRecord.vue'),
					meta: {
						title: '广告商审核记录'
					}
				},
				{
					path: 'eduBureauList',
					name: 'eduBureauList',
					component: () => import('@/views/eduBureau/eduBureauList.vue'),
					meta: {
						title: '教育局列表'
					}
				},
				{
					path: 'advList',
					name: 'advList',
					component: () => import('@/views/adv/advList.vue'),
					meta: {
						title: '广告列表'
					}
				},
				{
					path: 'advUserDetail/:detailInfo',
					name: 'advUserDetail',
					component: () => import('@/views/advUser/advUserDetail.vue'),
					meta: {
						title: '充值详情'
					}
				},
				{
					path: 'advUserRecharge/:detailInfo',
					name: 'advUserRecharge',
					component: () => import('@/views/advUser/advUserRecharge.vue'),
					meta: {
						title: '广告主充值中心'
					}
				},
				{
					path: 'realauthList',
					name: 'realauthList',
					component: () => import('@/views/realauth/realauthList.vue'),
					meta: {
						title: '实名认证列表'
					}
				},
				{
					path: 'appealList',
					name: 'appealList',
					component: () => import('@/views/realauth/appealList.vue'),
					meta: {
						title: '实名认证申诉列表'
					}
				},
				{
					path: 'school',
					name: 'school',
					component: () => import('@/views/School/school.vue'),
					meta: {
						title: '添加学校'
					}
				},
				{
					path: 'schoolList',
					name: 'schoolList',
					component: () => import('@/views/School/schoolList.vue'),
					meta: {
						title: '学校列表'
					}
				},
				{
					path: 'schoolInfo',
					name: 'schoolInfo',
					component: () => import('@/views/School/schoolInfo.vue'),
					meta: {
						title: '学校数据'
					}
				},
				{
					path: 'levelList/:schoolId/:schoolTypeId',
					name: 'levelList',
					component: () => import('@/views/School/levelList.vue'),
					meta: {
						title: '年级列表'
					}
				},
				{
					path: 'classList/:levelId/:schoolId',
					name: 'classList',
					component: () => import('@/views/School/classList.vue'),
					meta: {
						title: '班级列表'
					}
				},
				{
					path: 'studentList/:levelId/:schoolId/:classId',
					name: 'studentList',
					component: () => import('@/views/School/studentList.vue'),
					meta: {
						title: '学生列表'
					}
				},
				{
					path: 'articleComplete',
					name: 'articleComplete',
					component: () => import('@/views/Article/articleComplete.vue'),
					meta: {
						title: '文章待审核'
					}
				},
				{
					path: 'UNarticle',
					name: 'UNarticle',
					component: () => import('@/views/Article/UNarticle.vue'),
					meta: {
						title: '文章已审核'
					}
				},
				{
					path: 'refuseArticle',
					name: 'refuseArticle',
					component: () => import('@/views/Article/refuseArticle.vue'),
					meta: {
						title: '文章已拒绝'
					}
				},
				{
					path: 'Addclassify',
					name: 'Addclassify',
					component: () => import('@/views/Article/Addclassify.vue'),
					meta: {
						title: '添加分类'
					}
				},
				{
					path: 'freeze',
					name: 'freeze',
					component: () => import('@/views/Article/freeze.vue'),
					meta: {
						title: '已冻结'
					}
				},
				{
					path: 'home',
					name: 'home',
					component: () => import('@/views/Home/home.vue'),
					meta: {
						title: '首页'
					}
				},
				{
					path: 'machine',
					name: 'machine',
					component: () => import('@/views/machine/machine.vue'),
					meta: {
						title: '设备列表'
					}
				},
				{
					path: 'appAdmin',
					name: 'appAdmin',
					component: () => import('@/views/APP/appAdmin.vue'),
					meta: {
						title: 'APP首页数据修改'
					}
				},
				{
					path: 'appUpdate',
					name: 'appUpdate',
					component: () => import('@/views/APP/appUpdate.vue'),
					meta: {
						title: 'APP版本更新管理'
					}
				},
				{
					path: 'pushNews',
					name: 'pushNews',
					component: () => import('@/views/APP/pushNews.vue'),
					meta: {
						title: 'APP发布文章'
					}
				},
				{
					path: 'userList',
					name: 'userList',
					component: () => import('@/views/APP/userList.vue'),
					meta: {
						title: 'APP用户表'
					}
				},
				{
					path: 'activityInfo',
					name: 'activityInfo',
					component: () => import('@/views/APP/activityInfo.vue'),
					meta: {
						title: '活动排名'
					}
				}
			]
		}
	]
})

export default router
